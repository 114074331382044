import React, { useEffect, useState } from 'react';
import './homefooter.css';
import logo from "../src/logo.jpg";
import masa10_1 from "../src/Masalar kopyası/FOTOĞRAF/10/1.jpg";

const Homefooter = () => {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const scrollThreshold = 100; // Adjust the threshold as needed

            setIsScrolled(scrollTop > scrollThreshold);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <footer className={`home-footer ${isScrolled ? 'scrolled' : ''}`}>
            <div className="home-footer-container">
                <div className="home-footer-column">
                    <h5 className="home-footer-heading">
                        <h5>

                        </h5>
                        As one of the pioneering companies in Global, we are at the forefront of bringing the latest global trends to the country with our constantly evolving structure.
                    </h5>
                    <p className="home-footer-text">
                        With our continuously updated corporate identity and a multitude of exciting innovations, our brand is dedicated to bringing your dreams to life. We pride ourselves on staying ahead of the curve and being a trendsetter in the industry, consistently introducing new and exciting concepts to the World market.
                    </p>
                    <p className="home-footer-text">
                        At our core, we believe in the power of innovation and strive to be at the forefront of global advancements. By closely monitoring international trends and developments, we ensure that we are always on top of the latest ideas, technologies, and designs. This enables us to provide our customers with cutting-edge products and services that cater to their ever-changing needs and desires.
                    </p>
                </div>
                <div className="home-footer-column">
                    <h5 className="home-footer-heading">
                        <img src={masa10_1} alt="masa10_1" className="Homefooter-smaller-image" />
                    </h5>
                    <p className="home-footer-text"></p>
                </div>
            </div>
        </footer>
    );
};

export default Homefooter;

import React, { useState, useEffect } from "react";
import './Contact.css';
import Footer from './footer';

const FORM_ENDPOINT = ""; // TODO - fill on the later step

const Contact = () => {
    useEffect(() => {
        if (document) {
            const stylesheet = document.createElement("link");
            stylesheet.rel = "stylesheet";
            stylesheet.href = "https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css";

            document.head.appendChild(stylesheet);
        }
    }, []);

    const [submitted, setSubmitted] = useState(false);
    const handleSubmit = () => {
        setTimeout(() => {
            setSubmitted(true);
        }, 100);
    };

    if (submitted) {
        return (
            <>
                <div className="text-2xl">Thank you!</div>
                <div className="text-md">We'll be in touch soon.</div>
            </>
        );
    }

    return (
        <div className="contact-page">


            <div className="contact-section">
                <div style={{ marginBottom: '20px' }}>
                    <p2>You can Reach to us by submitting the bottom form. We will get back to you as soon as we can.</p2>
                </div>
                <p2 style={{ marginBottom: '20px' }}>Reach to Us!</p2>
                <form
                    action={FORM_ENDPOINT}
                    onSubmit={handleSubmit}
                    method="POST"
                    target="_blank"
                    className="w-full max-w-sm"
                >
                    <div className="mb-3 pt-0">
                        <input
                            type="text"
                            placeholder="Name"
                            name="name"
                            className="input-field"
                            required
                        />
                    </div>
                    <div className="mb-3 pt-0">
                        <input
                            type="email"
                            placeholder="Email"
                            name="email"
                            className="input-field"
                            required
                        />
                    </div>
                    <div className="mb-3 pt-0">
                        <textarea
                            placeholder="Message"
                            name="message"
                            className="input-field"
                            required
                        />
                    </div>

                    <div className="mb-3 pt-0">
                        <button
                            className="submit-button"
                            type="submit"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
            <div className="location">
                <iframe
                    title="Location"
                    src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15666.87423278554!2d32.6949332!3d39.8635702!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2z39.8635702!2z32.6949332!5e0!3m2!1sen!2sus!4v1234567890`}
                    width="100%" // Set the width to 100%
                    height="auto"
                    frameBorder="0"
                    style={{ border: 0 }}
                    allowFullScreen
                ></iframe>
            </div>

            <Footer />
        </div>
    );
};

export default Contact;

import React from 'react';
import { Route, HashRouter } from 'react-router-dom';
import './App.css';
import ProductsPage from './products';
import Home from './Home';
import Navbar from './Navbar';
import Contact from './Contact';
import tables from './tables';
import chairs from './chairs';
import TestPage from './test';
import 'typeface-montserrat';


function App() {


  return (

    <div className="app">
      <HashRouter>
        <Navbar />
        <Route exact path="/" component={Home} />
        <Route path="/home" component={Home} />
        <Route path="/products" component={ProductsPage} />
        <Route path="/tables" component={tables} />
        <Route path="/chairs" component={chairs} />
        <Route path="/Contact" component={Contact} />
        <Route path="/test" component={TestPage} />
      </HashRouter>
    </div>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Link, NavLink } from 'react-router-dom';
import './navbar.css';
import logo from './logo.jpg';
import removedLogo from './logo-removebg-preview.png';


const Navbar = () => {
    const [activeLink, setActiveLink] = useState('');



    const handleLinkClick = (link) => {
        setActiveLink(link);
    };

    const [showNavbar, setShowNavbar] = React.useState(false);

    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar);
    };

    const handleNavbarLinkClick = () => {
        setShowNavbar(false);
        setActiveLink('');
    };

    const Hamburger = () => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="42"
            height="14"
            viewBox="0 0 52 24"
        >
            <g id="Group_9" data-name="Group 9" transform="translate(-294 -47)">
                <rect
                    id="Rectangle_3"
                    data-name="Rectangle 3"
                    width="42"
                    height="4"
                    rx="2"
                    transform="translate(304 47)"
                    fill="#574c4c"
                />
                <rect
                    id="Rectangle_5"
                    data-name="Rectangle 5"
                    width="42"
                    height="4"
                    rx="2"
                    transform="translate(304 67)"
                    fill="#574c4c"
                />
                <rect
                    id="Rectangle_4"
                    data-name="Rectangle 4"
                    width="52"
                    height="4"
                    rx="2"
                    transform="translate(294 57)"
                    fill="#574c4c"
                />
            </g>
        </svg>
    );

    return (
        <nav className={`navbar`}>
            <div className="navbar__container">

                <div className="navbar__logo">

                    <div className="logo-container">
                        <div className="menu-icon" onClick={handleShowNavbar}>
                            <Hamburger />
                        </div>
                        <Link to="/Home" className="navbar__logo">
                            <img src={removedLogo} alt="Logo" className="navbar__logo-image" />
                        </Link>

                    </div>


                </div>

                <div className={`nav-elements  ${showNavbar && "active"}`}>
                    <ul className="navbar__menu">
                        <li className="navbar__item">
                            <NavLink
                                exact
                                to="/Home"
                                className={`navbar__link ${activeLink === 'home' ? 'active' : ''}`}
                                onClick={() => {
                                    handleLinkClick('home');
                                    handleNavbarLinkClick();
                                }}
                            >
                                Home
                            </NavLink>
                        </li>
                        <li className="navbar__item">
                            <NavLink
                                exact
                                to="/products"
                                className={`navbar__link ${activeLink === 'products' ? 'active' : ''}`}
                                onClick={() => {
                                    handleLinkClick('products');
                                    handleNavbarLinkClick();
                                }}
                            >
                                Products
                            </NavLink>
                        </li>
                        <li className="navbar__item">
                            <NavLink
                                exact
                                to="/tables"
                                className={`navbar__link ${activeLink === 'tables' ? 'active' : ''}`}
                                onClick={() => {
                                    handleLinkClick('tables');
                                    handleNavbarLinkClick();
                                }}
                            >
                                Tables
                            </NavLink>
                        </li>
                        <li className="navbar__item">
                            <NavLink
                                exact
                                to="/chairs"
                                className={`navbar__link ${activeLink === 'chairs' ? 'active' : ''}`}
                                onClick={() => {
                                    handleLinkClick('chairs');
                                    handleNavbarLinkClick();
                                }}
                            >
                                Chairs
                            </NavLink>
                        </li>
                        <li className="navbar__item">
                            <NavLink
                                exact
                                to="/Contact"
                                className={`navbar__link ${activeLink === 'Contacts' ? 'active' : ''}`}
                                onClick={() => {
                                    handleLinkClick('Contact');
                                    handleNavbarLinkClick();
                                }}
                            >
                                Contact
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav >
    );
};

export default Navbar;

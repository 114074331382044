import React, { useState } from 'react';
import './products.css';
import { productsDataTable, productsDataChair } from './productsData';
import Footer from './footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowRight, faArrowUpRightDots, faChair, faTable } from '@fortawesome/free-solid-svg-icons';
import { FiChevronRight, FiFilter } from 'react-icons/fi';
import { FiChevronLeft } from 'react-icons/fi';
import { FaChair } from 'react-icons/fi';
import { TfiFilter } from 'react-icons/fi';
import { BsFillFilterSquareFill } from 'react-icons/fi';

import categoryButtonStandartTableImage from './standartTablesProduct.jpg';
import categoryButtonPremiumTableImage from './premiumTablesProduct.jpg';

import premiumExtensionButton from './premiumExtensionButton.jpg';
import premiumFixTopButton from './premiumFixTopButton.jpg';

import standartFixTopButton from './standartFixTopButton.jpg';
import standartExtension from './standartExtension.jpg';


import firstimage from './firstimage.jpg';
import Sandalyeler6 from "../src/Sandalyeler kopyası/Sandalyeler6.jpg";

import premiumBlack from './premiumBlack.jpg';
import standartBlack from './StandartBlack.jpg';




const ProductsPage = () => {
    const [selectedProductIndex, setSelectedProductIndex] = useState(null);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [selectedMainCategory, setSelectedMainCategory] = useState('');
    const [selectedSubTableCategory, setSelectedSubTableCategory] = useState('');



    const handlePrevImage = () => {
        setSelectedImageIndex((prevIndex) => (prevIndex - 1 + selectedProduct.img.length) % selectedProduct.img.length);
    };

    const handleNextImage = () => {
        setSelectedImageIndex((prevIndex) => (prevIndex + 1) % selectedProduct.img.length);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
    };


    const handleMainCategoryClick = (category) => {
        setSelectedMainCategory(category);
        setSelectedSubTableCategory('');

        if (category === 'All') {
            setSelectedCategory('Masa');
            setSelectedProductIndex(null);
        } else {
            setSelectedCategory('Masa');
            setSelectedSubTableCategory('All');
            setSelectedProductIndex(null);
        }
    };

    const handleSubTableCategoryClick = (category) => {
        setSelectedSubTableCategory(category);

        if (category === 'All') {
            setSelectedCategory('Masa');
            setSelectedProductIndex(null);
        }
    };

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        setSelectedMainCategory('');
        setSelectedSubTableCategory('');

        // Check if the selected category is "Masa" (Table)
        if (category === 'Masa') {
            setSelectedProductIndex(null); // Reset the selected product index
            setSelectedSubTableCategory(''); // Reset the sub-table category
        }
    };


    const mainCategories = [
        { label: 'All', value: null, image: null },
        { label: 'Standard', value: 'Standard', image: standartBlack },
        { label: 'Premium', value: 'Premium', image: premiumBlack },
    ];

    let subTableCategories = [];

    if (selectedMainCategory === 'Standard') {
        subTableCategories = [
            { label: 'Standard Fix Top', value: 'Standard Fix Top', image: standartFixTopButton },
            { label: 'Standard Extension', value: 'Standard Extension', image: standartExtension },
        ];
    } else if (selectedMainCategory === 'Premium') {
        subTableCategories = [
            { label: 'Premium Fix Top', value: 'Premium Fix Top', image: premiumFixTopButton },
            { label: 'Premium Extension', value: 'Premium Extension', image: premiumExtensionButton },
        ];
    }

    const productsData =
        selectedCategory === 'Masa'
            ? selectedSubTableCategory === 'All'
                ? productsDataTable
                : productsDataTable.filter((product) => product.subTableCategory === selectedSubTableCategory)
            : productsDataChair;


    const [selectedOption, setSelectedOption] = useState(3);

    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };


    const [selectedColor, setSelectedColor] = useState(productsDataChair[0].color);



    const handleProductClick = (productIndex, color) => {
        setSelectedProductIndex(productIndex);
        setSelectedColor(color);
        setSelectedImageIndex(0);
        setIsPopupOpen(true);
    };

    const handleColorOptionClick = (color) => {
        const product = productsData[selectedProductIndex];
        const productIndex = productsData.findIndex((p) => p.id === product.id && p.color === color);

        if (productIndex !== -1) {
            setSelectedProductIndex(productIndex);
            setSelectedColor(color);
            setSelectedImageIndex(0);
            setIsPopupOpen(true);
        } else {

        }
    };


    const selectedProduct =
        selectedProductIndex !== null ? productsData[selectedProductIndex] : null;


    return (
        <div className="products-page">
            <div className="product-text" style={{ background: 'linear-gradient(to right,#333, #443b3b)' }}>
                <h2>Products</h2>
            </div>
            <div className="content-container">
                <div className="sidebar">

                    <div className="grid-option">
                        <p style={{ paddingRight: '10px' }}> ⊞ Grid</p>
                        <button
                            className={`grid-option-button ${selectedOption === 3 ? 'active' : ''}`}
                            onClick={() => handleOptionChange(3)}
                            style={{ color: 'black' }}

                        >
                            -
                        </button>
                        <button
                            className={`grid-option-button ${selectedOption === 4 ? 'active' : ''}`}
                            onClick={() => handleOptionChange(4)}
                            style={{ color: 'black' }}
                        >
                            +
                        </button>
                    </div>
                    <p style={{ display: 'flex' }}>
                        Filter <FiFilter size={17} color="black" fontWeight="bold" />
                    </p>

                    <button className="sidebar-button"
                        style={{
                            backgroundImage: `url(${firstimage})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            color: 'white',
                            alignItems: 'center',
                            width: '140px',
                            maxWidth: '300px',
                            height: '40px',
                            fontWeight: 'bold',
                            fontSize: '14px'
                        }}

                        onClick={() => handleCategoryClick('Masa')}>
                        Table
                        <FontAwesomeIcon icon={faTable} style={{ color: 'white', paddingLeft: '10px', fontSize: 'small' }} />
                    </button>

                    <button className="sidebar-button"

                        style={{
                            backgroundImage: `url(${Sandalyeler6})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            color: 'white',
                            alignItems: 'center',
                            width: '140px',
                            maxWidth: '300px',
                            height: '40px',
                            fontWeight: 'bold',
                            fontSize: '14px'
                        }}

                        onClick={() => handleCategoryClick('Sandalye')}>
                        Chair
                        <FontAwesomeIcon icon={faChair} style={{ color: 'white', paddingLeft: '10px', fontSize: 'small' }} />
                    </button>


                    {selectedCategory === 'Masa' && (
                        <div>
                            <div className="mainCategory-filter">
                                <p>Main Category</p>
                                {mainCategories.map((category) => (
                                    <button
                                        key={category.value}
                                        className={`mainCategory-button ${selectedMainCategory === category.value ? 'active' : ''}`}
                                        onClick={() => handleMainCategoryClick(category.value)}
                                        style={{
                                            backgroundImage: `url(${category.image})`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            color: 'white',
                                            alignItems: 'center',
                                            width: '140px',
                                            maxWidth: '300px',
                                            height: '40px',
                                            fontWeight: 'bold',
                                            fontSize: '14px',
                                            textAlign: 'center'

                                        }}
                                    >

                                        {category.label}

                                    </button>
                                ))}
                            </div>


                            {selectedMainCategory && selectedMainCategory !== 'All' && (
                                <div className="subTableCategory-filter">
                                    <p style={{ fontSize: '16px' }}>Sub Category</p>
                                    {subTableCategories.map((category) => (
                                        <button
                                            key={category.value}
                                            className={`subTableCategory-button ${selectedSubTableCategory === category.value ? 'active' : ''}`}
                                            onClick={() => handleSubTableCategoryClick(category.value)}
                                            style={{
                                                backgroundImage: `url(${category.image})`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                                color: 'white',
                                                alignItems: 'center',
                                                width: '140px',
                                                maxWidth: '300px',
                                                height: '40px',
                                                fontWeight: 'bold',
                                                fontSize: '13px'


                                            }}
                                        >
                                            {category.label}

                                        </button>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}

                </div>


                <div className={`product-list grid-${selectedOption}`}>
                    {productsData.map((product, index) => {
                        const sameProducts = productsData.filter((p) => p.id === product.id && p.color !== product.color);

                        return (
                            <div className="chairs-product-image" key={index}>
                                <img
                                    src={product.img[0]}
                                    alt={product.title}
                                    onClick={() => handleProductClick(index, product.color)}
                                />
                                <h3 style={{ fontWeight: 'bold' }}>{"🔖 " + product.title}</h3>
                                {product.subCategory &&

                                    <p style={{ fontWeight: 'bolder', fontSize: '14px' }}> ⧉⏐{product.subCategory} / {product.subTableCategory} </p>
                                }
                                {product.color &&
                                    <p1 style={{
                                        fontSize: '14px',
                                        backgroundColor: 'white',
                                        color: 'black',
                                        borderRadius: '10px',


                                    }}>{"▧ " + product.color}

                                    </p1>
                                }
                                {
                                    sameProducts.length > 0 && (
                                        <div>
                                            <p1 style={{ fontSize: '14px', paddingRight: '10px' }}>◯ Other Colors</p1>
                                            {sameProducts.map((p, i) => (
                                                <button
                                                    key={i}
                                                    onClick={() => handleColorOptionClick(p.color)}
                                                    style={{
                                                        backgroundColor: 'grey',
                                                        padding: '3px',
                                                        color: 'white',
                                                        borderRadius: '10px',
                                                        fontSize: '14px',
                                                        borderColor: 'black',
                                                        opacity: '0.5',

                                                    }}
                                                    className={selectedColor === p.color ? 'selected' : ''}
                                                >

                                                    {p.color}


                                                </button>
                                            ))}
                                        </div>
                                    )
                                }
                                {product.top &&
                                    <p style={{ fontSize: '14PX' }}>{"▧  Top " + product.Top}</p>
                                }

                                {product.Leg &&
                                    <p style={{ fontSize: '14PX' }}>{"𖡻  Leg " + product.Leg}</p>
                                }

                                {product.Base &&
                                    <p style={{ fontSize: '14PX' }}>{"𐬽  Base " + product.Base}</p>
                                }
                                {product.Size &&
                                    <p style={{ fontSize: '14PX' }}>{"⬤  Size " + product.Size}</p>
                                }

                            </div>
                        );
                    })}


                </div>


            </div>
            {
                isPopupOpen && (
                    <div className="popup-overlay" onClick={closePopup}>
                        <div className="popup" onClick={(event) => event.stopPropagation()}>
                            <div className="popup-image">
                                <img src={selectedProduct.img[selectedImageIndex]} alt={selectedProduct.title} />
                            </div>
                            <div className="popup-nav">
                                <button className="popup-nav-button" onClick={(event) => { event.stopPropagation(); handlePrevImage(); }}>
                                    ←
                                </button>
                                <button className="popup-nav-button" onClick={(event) => { event.stopPropagation(); handleNextImage(); }}>
                                    →
                                </button>
                            </div>
                        </div>
                    </div>
                )
            }
            <Footer />
        </div >
    );
};

export default ProductsPage;

import React from 'react';
import './footer.css';
import Masa6_3 from "../src/Masalar kopyası/FOTOĞRAF/6/3.jpg";
import logo from "../src/logo.jpg";
import removedLogo from './logo-removebg-preview.png';
import { useHistory } from 'react-router-dom';

const Footer = () => {
    const history = useHistory();

    const activeSection = 'masalar';
    const handleArrowChairClick = () => {
        history.push('/chairs'); // Navigate to the 'src/chairs.js' page
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleArrowTableClick = () => {
        history.push('/tables'); // Navigate to the 'src/chairs.js' page
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    const handleArrowContactClick = () => {
        history.push('/Contact'); // Navigate to the 'src/chairs.js' page
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-column">
                    <h5 className="footer-heading">
                        <img src={removedLogo} alt="Logo" style={{ width: '%100', maxWidth: '150px', height: 'auto' }} />
                    </h5>

                    <p className="footer-text">
                        <button style={{ backgroundColor: 'transparent', border: 'none', cursor: 'pointer', color: 'black', textDecoration: 'underline' }} onClick={handleArrowChairClick}>
                            Chairs
                        </button>
                    </p>
                    <p className="footer-text">
                        <button style={{ backgroundColor: 'transparent', border: 'none', cursor: 'pointer', color: 'black', textDecoration: 'underline' }} onClick={handleArrowTableClick}>
                            Tables
                        </button>
                    </p>
                    <p className="footer-text">
                        <button style={{ backgroundColor: 'transparent', border: 'none', cursor: 'pointer', color: 'black', textDecoration: 'underline' }} onClick={handleArrowContactClick}>
                            Contact
                        </button>
                    </p>
                </div>
                <div className="footer-column">
                    <h5 className="footer-heading">Reach to Us!</h5>
                    <p className="footer-text">
                        Email: info@designtableetc.com
                    </p>
                    <h5>
                        Location: Design Table Etc Store
                    </h5>
                </div>
            </div>
            <div className="footer-bottom">
                <p className="footer-text">&copy; {new Date().getFullYear()} All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;

import Masa1_1 from "../src/Masalar kopyası/FOTOĞRAF/1/1.jpg";
import Masa1_2 from "../src/Masalar kopyası/FOTOĞRAF/1/2.jpg";
import Masa1_3 from "../src/Masalar kopyası/FOTOĞRAF/1/3.jpg";
import Masa2_1 from "../src/Masalar kopyası/FOTOĞRAF/2/1.jpg";
import Masa2_2 from "../src/Masalar kopyası/FOTOĞRAF/2/2.jpg";
import Masa2_3 from "../src/Masalar kopyası/FOTOĞRAF/2/3.jpg";
import Masa2_4 from "../src/Masalar kopyası/FOTOĞRAF/2/4.jpg";
import Masa2_5 from "../src/Masalar kopyası/FOTOĞRAF/2/5.jpg";
import Masa2_6 from "../src/Masalar kopyası/FOTOĞRAF/2/6.jpg";
import Masa2_7 from "../src/Masalar kopyası/FOTOĞRAF/2/7.jpg";
import Masa3_1 from "../src/Masalar kopyası/FOTOĞRAF/3/1.jpg";
import Masa3_2 from "../src/Masalar kopyası/FOTOĞRAF/3/2.jpg";
import Masa3_3 from "../src/Masalar kopyası/FOTOĞRAF/3/3.jpg";
import Masa4_1 from "../src/Masalar kopyası/FOTOĞRAF/4/1.jpg";
import Masa4_2 from "../src/Masalar kopyası/FOTOĞRAF/4/2.jpg";
import Masa4_3 from "../src/Masalar kopyası/FOTOĞRAF/4/3.jpg";
import Masa5_1 from "../src/Masalar kopyası/FOTOĞRAF/5/1.jpg";
import Masa5_2 from "../src/Masalar kopyası/FOTOĞRAF/5/2.jpg";
import Masa5_3 from "../src/Masalar kopyası/FOTOĞRAF/5/3.jpg";
import Masa6_1 from "../src/Masalar kopyası/FOTOĞRAF/6/1.jpg";
import Masa6_2 from "../src/Masalar kopyası/FOTOĞRAF/6/2.jpg";
import Masa6_3 from "../src/Masalar kopyası/FOTOĞRAF/6/3.jpg";
import Masa6_4 from "../src/Masalar kopyası/FOTOĞRAF/6/4.jpg";
import Masa7_1 from "../src/Masalar kopyası/FOTOĞRAF/7/1.jpg";
import Masa7_1a from "../src/Masalar kopyası/FOTOĞRAF/7/1a.jpg";
import Masa7_1b from "../src/Masalar kopyası/FOTOĞRAF/7/1b.jpg";
import Masa7_2 from "../src/Masalar kopyası/FOTOĞRAF/7/2.jpg";
import Masa7_3 from "../src/Masalar kopyası/FOTOĞRAF/7/3.jpg";
import Masa7_4 from "../src/Masalar kopyası/FOTOĞRAF/7/4.jpg";
import Masa8_1 from "../src/Masalar kopyası/FOTOĞRAF/8/1.jpg";
import Masa8_2 from "../src/Masalar kopyası/FOTOĞRAF/8/2.jpg";
import Masa8_3 from "../src/Masalar kopyası/FOTOĞRAF/8/3.jpg";
import Masa9_1 from "../src/Masalar kopyası/FOTOĞRAF/9/1.jpg";
import Masa9_2 from "../src/Masalar kopyası/FOTOĞRAF/9/2.jpg";
import Masa9_3 from "../src/Masalar kopyası/FOTOĞRAF/9/3.jpg";
import Masa10_1 from "../src/Masalar kopyası/FOTOĞRAF/10/1.jpg";
import Masa10_1a from "../src/Masalar kopyası/FOTOĞRAF/10/1a.jpg";
import Masa10_1b from "../src/Masalar kopyası/FOTOĞRAF/10/1b.jpg";
import Masa10_2 from "../src/Masalar kopyası/FOTOĞRAF/10/2.jpg";
import Masa10_2q from "../src/Masalar kopyası/FOTOĞRAF/10/2q.jpg";
import Masa10_3 from "../src/Masalar kopyası/FOTOĞRAF/10/3.jpg";
import Masa11_1 from "../src/Masalar kopyası/FOTOĞRAF/11/1.jpg";
import Masa11_2 from "../src/Masalar kopyası/FOTOĞRAF/11/2.jpg";
import Masa11_3 from "../src/Masalar kopyası/FOTOĞRAF/11/3.jpg";
import Masa12_1 from "../src/Masalar kopyası/FOTOĞRAF/12/1.jpg";
import Masa12_2 from "../src/Masalar kopyası/FOTOĞRAF/12/2.jpg";
import Masa12_3 from "../src/Masalar kopyası/FOTOĞRAF/12/3.jpg";
import Masa12_3a from "../src/Masalar kopyası/FOTOĞRAF/12/3a.jpg";
import Masa13_1 from "../src/Masalar kopyası/FOTOĞRAF/13/1.jpg";
import Masa13_2 from "../src/Masalar kopyası/FOTOĞRAF/13/2.jpg";
import Masa13_3 from "../src/Masalar kopyası/FOTOĞRAF/13/3.jpg";
import Masa13_4 from "../src/Masalar kopyası/FOTOĞRAF/13/4.jpg";
import Masa14_1 from "../src/Masalar kopyası/FOTOĞRAF/14/1.jpg";
import Masa14_2 from "../src/Masalar kopyası/FOTOĞRAF/14/2.jpg";
import Masa14_3 from "../src/Masalar kopyası/FOTOĞRAF/14/3.jpg";
import Masa15_1 from "../src/Masalar kopyası/FOTOĞRAF/15/1.jpg";
import Masa15_2 from "../src/Masalar kopyası/FOTOĞRAF/15/2.jpg";
import Masa15_3 from "../src/Masalar kopyası/FOTOĞRAF/15/3.jpg";
import Masa16_1 from "../src/Masalar kopyası/FOTOĞRAF/16/1.jpg";
import Masa16_2 from "../src/Masalar kopyası/FOTOĞRAF/16/2.jpg";
import Masa16_3 from "../src/Masalar kopyası/FOTOĞRAF/16/3.jpg";
import Masa17_1 from "../src/Masalar kopyası/FOTOĞRAF/17/1.jpg";
import Masa17_2 from "../src/Masalar kopyası/FOTOĞRAF/17/2.jpg";
import Masa17_2a from "../src/Masalar kopyası/FOTOĞRAF/17/2a.jpg";
import Masa18_1 from "../src/Masalar kopyası/FOTOĞRAF/18/1.jpg";
import Masa18_1a from "../src/Masalar kopyası/FOTOĞRAF/18/1a.jpg";
import Masa18_2 from "../src/Masalar kopyası/FOTOĞRAF/18/2.jpg";
import Masa18_3 from "../src/Masalar kopyası/FOTOĞRAF/18/3.jpg";
import Masa18_4 from "../src/Masalar kopyası/FOTOĞRAF/18/4.jpg";
import Masa19_1 from "../src/Masalar kopyası/FOTOĞRAF/19/1.jpg";
import Masa19_2 from "../src/Masalar kopyası/FOTOĞRAF/19/2.jpg";
import Masa19_3 from "../src/Masalar kopyası/FOTOĞRAF/19/3.jpg";
import Masa19_4 from "../src/Masalar kopyası/FOTOĞRAF/19/4.jpg";
import Masa20_1 from "../src/Masalar kopyası/FOTOĞRAF/20/1.jpg";
import Masa20_2 from "../src/Masalar kopyası/FOTOĞRAF/20/2.jpg";
import Masa20_3 from "../src/Masalar kopyası/FOTOĞRAF/20/3.jpg";
import Masa21_1 from "../src/Masalar kopyası/FOTOĞRAF/21/1.jpg";
import Masa21_2 from "../src/Masalar kopyası/FOTOĞRAF/21/2.jpg";
import Masa21_3 from "../src/Masalar kopyası/FOTOĞRAF/21/3.jpg";
import Masa22_1 from "../src/Masalar kopyası/FOTOĞRAF/22/1.jpg";
import Masa22_2 from "../src/Masalar kopyası/FOTOĞRAF/22/2.jpg";
import Masa22_3 from "../src/Masalar kopyası/FOTOĞRAF/22/3.jpg";
import Masa22_4 from "../src/Masalar kopyası/FOTOĞRAF/22/4.jpg";
import Masa22_5 from "../src/Masalar kopyası/FOTOĞRAF/22/5.jpg";
import Masa23_1 from "../src/Masalar kopyası/FOTOĞRAF/23/1.jpg";
import Masa23_2 from "../src/Masalar kopyası/FOTOĞRAF/23/2.jpg";
import Masa23_3 from "../src/Masalar kopyası/FOTOĞRAF/23/3.jpg";
import Masa24_1 from "../src/Masalar kopyası/FOTOĞRAF/24/1.jpg";
import Masa24_2 from "../src/Masalar kopyası/FOTOĞRAF/24/2.jpg";
import Masa24_3 from "../src/Masalar kopyası/FOTOĞRAF/24/3.jpg";
import Masa24_4 from "../src/Masalar kopyası/FOTOĞRAF/24/4.jpg";
import Masa24_5 from "../src/Masalar kopyası/FOTOĞRAF/24/5.jpg";
import Masa24_6 from "../src/Masalar kopyası/FOTOĞRAF/24/6.jpg";
import Masa25_1 from "../src/Masalar kopyası/FOTOĞRAF/25/1.jpg";
import Masa25_2 from "../src/Masalar kopyası/FOTOĞRAF/25/2.jpg";
import Masa25_3 from "../src/Masalar kopyası/FOTOĞRAF/25/3.jpg";
import Masa25_4 from "../src/Masalar kopyası/FOTOĞRAF/25/4.jpg";
import Masa26_1 from "../src/Masalar kopyası/FOTOĞRAF/26/1.jpg";
import Masa26_2 from "../src/Masalar kopyası/FOTOĞRAF/26/2.jpg";
import Masa26_3 from "../src/Masalar kopyası/FOTOĞRAF/26/3.jpg";
import Masa26_4 from "../src/Masalar kopyası/FOTOĞRAF/26/4.jpg";
import Masa27_1 from "../src/Masalar kopyası/FOTOĞRAF/27/1.jpg";
import Masa27_2 from "../src/Masalar kopyası/FOTOĞRAF/27/2.jpg";
import Masa27_3 from "../src/Masalar kopyası/FOTOĞRAF/27/3.jpg";
import k1 from "../src/k1.jpg";
import k2 from "../src/k2.jpg";



import Sandalyeler6 from "../src/Sandalyeler kopyası/Sandalyeler6.jpg";
import Sandalyeler7 from "../src/Sandalyeler kopyası/Sandalyeler7.jpg";
import Sandalyeler8 from "../src/Sandalyeler kopyası/Sandalyeler8.jpg";
import Sandalyeler9 from "../src/Sandalyeler kopyası/Sandalyeler9.jpg";
import Sandalyeler10 from "../src/Sandalyeler kopyası/Sandalyeler10.jpg";
import Sandalyeler11 from "../src/Sandalyeler kopyası/Sandalyeler11.jpg";
import Sandalyeler12 from "../src/Sandalyeler kopyası/Sandalyeler12.jpg";
import Sandalyeler13 from "../src/Sandalyeler kopyası/Sandalyeler13.jpg";
import Sandalyeler14 from "../src/Sandalyeler kopyası/Sandalyeler14.jpg";
import Sandalyeler15 from "../src/Sandalyeler kopyası/Sandalyeler15.jpg";
import Sandalyeler16 from "../src/Sandalyeler kopyası/Sandalyeler16.jpg";
import Sandalyeler17 from "../src/Sandalyeler kopyası/Sandalyeler17.jpg";
import Sandalyeler18 from "../src/Sandalyeler kopyası/Sandalyeler18.jpg";
import Sandalyeler19 from "../src/Sandalyeler kopyası/Sandalyeler19.jpg";
import Sandalyeler20 from "../src/Sandalyeler kopyası/Sandalyeler20.jpg";
import Sandalyeler21 from "../src/Sandalyeler kopyası/Sandalyeler21.jpg";
import Sandalyeler22 from "../src/Sandalyeler kopyası/Sandalyeler22.jpg";
import Sandalyeler23 from "../src/Sandalyeler kopyası/Sandalyeler23.jpg";
import Sandalyeler24 from "../src/Sandalyeler kopyası/Sandalyeler24.jpg";
import Sandalyeler25 from "../src/Sandalyeler kopyası/Sandalyeler25.jpg";
import Sandalyeler26 from "../src/Sandalyeler kopyası/Sandalyeler26.jpg";
import Sandalyeler27 from "../src/Sandalyeler kopyası/Sandalyeler27.jpg";
import Sandalyeler28 from "../src/Sandalyeler kopyası/Sandalyeler28.jpg";
import Sandalyeler29 from "../src/Sandalyeler kopyası/Sandalyeler29.jpg";
import Sandalyeler30 from "../src/Sandalyeler kopyası/Sandalyeler30.jpg";
import Sandalyeler31 from "../src/Sandalyeler kopyası/Sandalyeler31.jpg";
import Sandalyeler32 from "../src/Sandalyeler kopyası/Sandalyeler32.jpg";
import Sandalyeler33 from "../src/Sandalyeler kopyası/Sandalyeler33.jpg";
import Sandalyeler34 from "../src/Sandalyeler kopyası/Sandalyeler34.jpg";
import Sandalyeler35 from "../src/Sandalyeler kopyası/Sandalyeler35.jpg";
import Sandalyeler36 from "../src/Sandalyeler kopyası/Sandalyeler36.jpg";
import Sandalyeler37 from "../src/Sandalyeler kopyası/Sandalyeler37.jpg";
import Sandalyeler38 from "../src/Sandalyeler kopyası/Sandalyeler38.jpg";
import Sandalyeler39 from "../src/Sandalyeler kopyası/Sandalyeler39.jpg";
import Sandalyeler40 from "../src/Sandalyeler kopyası/Sandalyeler40.jpg";
import Sandalyeler41 from "../src/Sandalyeler kopyası/Sandalyeler41.jpg";
import Sandalyeler42 from "../src/Sandalyeler kopyası/Sandalyeler42.jpg";
import Sandalyeler43 from "../src/Sandalyeler kopyası/Sandalyeler43.jpg";
import Sandalyeler44 from "../src/Sandalyeler kopyası/Sandalyeler44.jpg";
import Sandalyeler45 from "../src/Sandalyeler kopyası/Sandalyeler45.jpg";
import Sandalyeler46 from "../src/Sandalyeler kopyası/Sandalyeler46.jpg";
import Sandalyeler47 from "../src/Sandalyeler kopyası/Sandalyeler47.jpg";
import Sandalyeler48 from "../src/Sandalyeler kopyası/Sandalyeler48.jpg";
import Sandalyeler49 from "../src/Sandalyeler kopyası/Sandalyeler49.jpg";
import Sandalyeler50 from "../src/Sandalyeler kopyası/Sandalyeler50.jpg";
import Sandalyeler51 from "../src/Sandalyeler kopyası/Sandalyeler51.jpg";
import Sandalyeler52 from "../src/Sandalyeler kopyası/Sandalyeler52.jpg";
import Sandalyeler53 from "../src/Sandalyeler kopyası/Sandalyeler53.jpg";
import Sandalyeler54 from "../src/Sandalyeler kopyası/Sandalyeler54.jpg";
import Sandalyeler55 from "../src/Sandalyeler kopyası/Sandalyeler55.jpg";
import Sandalyeler56 from "../src/Sandalyeler kopyası/Sandalyeler56.jpg";
import Sandalyeler57 from "../src/Sandalyeler kopyası/Sandalyeler57.jpg";
import Sandalyeler58 from "../src/Sandalyeler kopyası/Sandalyeler58.jpg";
import Sandalyeler59 from "../src/Sandalyeler kopyası/Sandalyeler59.jpg";
import Sandalyeler60 from "../src/Sandalyeler kopyası/Sandalyeler60.jpg";
import Sandalyeler61 from "../src/Sandalyeler kopyası/Sandalyeler61.jpg";
import Sandalyeler62 from "../src/Sandalyeler kopyası/Sandalyeler62.jpg";
import Sandalyeler63 from "../src/Sandalyeler kopyası/Sandalyeler63.jpg";
import Sandalyeler64 from "../src/Sandalyeler kopyası/Sandalyeler64.jpg";
import Sandalyeler65 from "../src/Sandalyeler kopyası/Sandalyeler65.jpg";
import Sandalyeler66 from "../src/Sandalyeler kopyası/Sandalyeler66.jpg";
import Sandalyeler67 from "../src/Sandalyeler kopyası/Sandalyeler67.jpg";
import Sandalyeler68 from "../src/Sandalyeler kopyası/Sandalyeler68.jpg";
import Sandalyeler69 from "../src/Sandalyeler kopyası/Sandalyeler69.jpg";
import Sandalyeler70 from "../src/Sandalyeler kopyası/Sandalyeler70.jpg";
import Sandalyeler71 from "../src/Sandalyeler kopyası/Sandalyeler71.jpg";
import Sandalyeler72 from "../src/Sandalyeler kopyası/Sandalyeler72.jpg";
import Sandalyeler73 from "../src/Sandalyeler kopyası/Sandalyeler73.jpg";
import Sandalyeler74 from "../src/Sandalyeler kopyası/Sandalyeler74.jpg";
import Sandalyeler75 from "../src/Sandalyeler kopyası/Sandalyeler75.jpg";
import Sandalyeler76 from "../src/Sandalyeler kopyası/Sandalyeler76.jpg";
import Sandalyeler77 from "../src/Sandalyeler kopyası/Sandalyeler77.jpg";
import Sandalyeler78 from "../src/Sandalyeler kopyası/Sandalyeler78.jpg";
import Sandalyeler79 from "../src/Sandalyeler kopyası/Sandalyeler79.jpg";
import Sandalyeler80 from "../src/Sandalyeler kopyası/Sandalyeler80.jpg";
import Sandalyeler81 from "../src/Sandalyeler kopyası/Sandalyeler81.jpg";
import Sandalyeler82 from "../src/Sandalyeler kopyası/Sandalyeler82.jpg";
import Sandalyeler83 from "../src/Sandalyeler kopyası/Sandalyeler83.jpg";
import Sandalyeler84 from "../src/Sandalyeler kopyası/Sandalyeler84.jpg";
import Sandalyeler85 from "../src/Sandalyeler kopyası/Sandalyeler85.jpg";
import Sandalyeler86 from "../src/Sandalyeler kopyası/Sandalyeler86.jpg";
import Sandalyeler87 from "../src/Sandalyeler kopyası/Sandalyeler87.jpg";
import Sandalyeler88 from "../src/Sandalyeler kopyası/Sandalyeler88.jpg";
import Sandalyeler89 from "../src/Sandalyeler kopyası/Sandalyeler89.jpg";
import Sandalyeler90 from "../src/Sandalyeler kopyası/Sandalyeler90.jpg";
import Sandalyeler91 from "../src/Sandalyeler kopyası/Sandalyeler91.jpg";
import Sandalyeler92 from "../src/Sandalyeler kopyası/Sandalyeler92.jpg";
import Sandalyeler93 from "../src/Sandalyeler kopyası/Sandalyeler93.jpg";
import Sandalyeler94 from "../src/Sandalyeler kopyası/Sandalyeler94.jpg";
import Sandalyeler95 from "../src/Sandalyeler kopyası/Sandalyeler95.jpg";
import Sandalyeler96 from "../src/Sandalyeler kopyası/Sandalyeler96.jpg";
import Sandalyeler97 from "../src/Sandalyeler kopyası/Sandalyeler97.jpg";
import Sandalyeler98 from "../src/Sandalyeler kopyası/Sandalyeler98.jpg";
import Sandalyeler99 from "../src/Sandalyeler kopyası/Sandalyeler99.jpg";
import Sandalyeler100 from "../src/Sandalyeler kopyası/Sandalyeler100.jpg";
import Sandalyeler101 from "../src/Sandalyeler kopyası/Sandalyeler101.jpg";
import Sandalyeler102 from "../src/Sandalyeler kopyası/Sandalyeler102.jpg";
import Sandalyeler103 from "../src/Sandalyeler kopyası/Sandalyeler103.jpg";
import Sandalyeler104 from "../src/Sandalyeler kopyası/Sandalyeler104.jpg";
import Sandalyeler105 from "../src/Sandalyeler kopyası/Sandalyeler105.jpg";
import Sandalyeler106 from "../src/Sandalyeler kopyası/Sandalyeler106.jpg";
import Sandalyeler107 from "../src/Sandalyeler kopyası/Sandalyeler107.jpg";
import Sandalyeler108 from "../src/Sandalyeler kopyası/Sandalyeler108.jpg";
import Sandalyeler109 from "../src/Sandalyeler kopyası/Sandalyeler109.jpg";
import Sandalyeler110 from "../src/Sandalyeler kopyası/Sandalyeler110.jpg";
import Sandalyeler111 from "../src/Sandalyeler kopyası/Sandalyeler111.jpg";
import Sandalyeler112 from "../src/Sandalyeler kopyası/Sandalyeler112.jpg";
import Sandalyeler113 from "../src/Sandalyeler kopyası/Sandalyeler113.jpg";
import Sandalyeler114 from "../src/Sandalyeler kopyası/Sandalyeler114.jpg";
import Sandalyeler115 from "../src/Sandalyeler kopyası/Sandalyeler115.jpg";
import Sandalyeler116 from "../src/Sandalyeler kopyası/Sandalyeler116.jpg";
import Sandalyeler117 from "../src/Sandalyeler kopyası/Sandalyeler117.jpg";
import Sandalyeler118 from "../src/Sandalyeler kopyası/Sandalyeler118.jpg";
import Sandalyeler119 from "../src/Sandalyeler kopyası/Sandalyeler119.jpg";
import Sandalyeler120 from "../src/Sandalyeler kopyası/Sandalyeler120.jpg";
import Sandalyeler121 from "../src/Sandalyeler kopyası/Sandalyeler121.jpg";
import Sandalyeler122 from "../src/Sandalyeler kopyası/Sandalyeler122.jpg";
import Sandalyeler123 from "../src/Sandalyeler kopyası/Sandalyeler123.jpg";
import Sandalyeler124 from "../src/Sandalyeler kopyası/Sandalyeler124.jpg";
import Sandalyeler125 from "../src/Sandalyeler kopyası/Sandalyeler125.jpg";
import Sandalyeler126 from "../src/Sandalyeler kopyası/Sandalyeler126.jpg";
import Sandalyeler127 from "../src/Sandalyeler kopyası/Sandalyeler127.jpg";
import Sandalyeler128 from "../src/Sandalyeler kopyası/Sandalyeler128.jpg";
import Sandalyeler129 from "../src/Sandalyeler kopyası/Sandalyeler129.jpg";
import Sandalyeler130 from "../src/Sandalyeler kopyası/Sandalyeler130.jpg";
import Sandalyeler131 from "../src/Sandalyeler kopyası/Sandalyeler131.jpg";
import Sandalyeler132 from "../src/Sandalyeler kopyası/Sandalyeler132.jpg";
import Sandalyeler133 from "../src/Sandalyeler kopyası/Sandalyeler133.jpg";
import Sandalyeler134 from "../src/Sandalyeler kopyası/Sandalyeler134.jpg";
import Sandalyeler135 from "../src/Sandalyeler kopyası/Sandalyeler135.jpg";
import Sandalyeler136 from "../src/Sandalyeler kopyası/Sandalyeler136.jpg";
import Sandalyeler137 from "../src/Sandalyeler kopyası/Sandalyeler137.jpg";
import Sandalyeler138 from "../src/Sandalyeler kopyası/Sandalyeler138.jpg";
import Sandalyeler139 from "../src/Sandalyeler kopyası/Sandalyeler139.jpg";
import Sandalyeler140 from "../src/Sandalyeler kopyası/Sandalyeler140.jpg";
import Sandalyeler141 from "../src/Sandalyeler kopyası/Sandalyeler141.jpg";
import Sandalyeler142 from "../src/Sandalyeler kopyası/Sandalyeler142.jpg";
import Sandalyeler143 from "../src/Sandalyeler kopyası/Sandalyeler143.jpg";




export const productsDataTable = [

    {
        title: "HT90102",
        img: [Masa1_1, Masa1_2, Masa1_3, k1],
        category: "Masa",
        subCategory: "Standard",
        subTableCategory: "Standard Fix Top",
        id: "HT90102",
        parentId: "1",
        Top: "11 mm ceramic Patagonia",
        Leg: "Iron cross in black brushed",
        Size: "L180xW90xH76",

    },

    {
        title: "HT93011",
        img: [Masa2_1, Masa2_2, Masa2_3, Masa2_4, Masa2_5, Masa2_6, Masa2_7, k2],
        category: "Masa",
        subCategory: "Standard",
        subTableCategory: "Standard Extension",
        id: "HT93011",
        parentId: "2",
        Top: "8mm glass +3mm ceramic KL 135",
        Legs: "Grey Anthracite powder coated",
        Size: "L130/190xW100xH76",
    },

    {
        title: "ZK1024DT-160",
        img: [Masa3_1, Masa3_2, Masa3_3],
        category: "Masa",
        subCategory: "Premium",
        subTableCategory: "Premium Extension",
        id: "ZK1024DT-160",
        parentId: "3",
        Top: "10mm glass + 3mm (KL 187)",
        Base: "Stainless steel in Golden brushed (SY 13) EXTENSION IN TWO SIDES",
        Size: "L160/240xW95xH76",

    },
    {
        title: "HT95006",
        img: [Masa4_1, Masa4_2, Masa4_3],
        category: "Masa",
        subCategory: "Premium",
        subTableCategory: "Premium Extension",
        id: "HT95006",
        parentId: "4",
        Top: "10mm glass+3mm ceramic TL-03",
        Base: "Grey anthracite powder coated",
        Size: "L200/280xW100xH77",
    },
    {
        title: "HT90052",
        img: [Masa5_1, Masa5_2, Masa5_3],
        category: "Masa",
        subCategory: "Standard",
        subTableCategory: "Standard Fix Top",
        id: "HT90052",
        parentId: "5",
        Top: "10mm glass + 3mm ceramicKL 116",
        Leg: "Aluminum in black brushed finish",
        Size: "L140xW80xH76 & L180xW90xH76 & L220xW100xH76",
    },
    {
        title: "ZK1001DT",
        img: [Masa6_1, Masa6_2, Masa6_3, Masa6_4],
        category: "Masa",
        subCategory: "Premium",
        subTableCategory: "Premium Fix Top",
        id: "ZK1001DT",
        parentId: "6",
        Top: "8mm glass + 6mm ceramic (Pandora)",
        Base: "Iron cross in golden brushed",
        Size: "L198xW88x76",
    },
    {
        title: "HT2590",
        img: [Masa7_1, Masa7_1a, Masa7_2, Masa7_2, Masa7_3, Masa7_4, Masa7_1b],
        category: "Masa",
        subCategory: "Premium",
        subTableCategory: "Premium Extension",
        id: "HT2590",
        parentId: "7",
        Top: "10mm glass + 3mm ceramic (KL 99)",
        Leg: "Black powder coated",
        Size: "L120/180xW80xH76 & L160/240xW90xH76 & L200/300xW100xH76",
    },
    {
        title: "HT90066",
        img: [Masa8_1, Masa8_2, Masa8_3],
        category: "Masa",
        subCategory: "Standard",
        subTableCategory: "Standard Fix Top",
        id: "HT90066",
        parentId: "8",
        Top: "10mm glass+ 3mm ceramic TL 188",
        Base: "Black powder coated",
        Size: "Ø120xH76 & Ø135xH76",
    },
    {
        title: "HT90102",
        img: [Masa9_1, Masa9_2, Masa9_3],
        category: "Masa",
        subCategory: "Standard",
        subTableCategory: "Standard Fix Top",
        id: "HT90102",
        parentId: "9",
        Top: "11 mm ceramic Patagonia",
        Leg: "Iron cross in black brushed",
        Size: "L180xW90xH76",

    },
    {
        title: "HT91016",
        img: [Masa10_1, Masa10_1a, Masa10_1b, Masa10_2, Masa10_2q, Masa10_3],
        category: "Masa",
        subCategory: "Standard",
        subTableCategory: "Standard Extension",
        id: "HT91016",
        parentId: "10",
        Top: "10mm glass + 3mm ceramic (KL 15)",
        Leg: "Black powder coated",
        Size: "L120/160xW80xH76 & L160/210xW100xH76  & L200/280xW100xH77",
    },
    {
        title: "HT90018",
        img: [Masa11_1, Masa11_2, Masa11_3],
        category: "Masa",
        subCategory: "Standard",
        subTableCategory: "Standard Fix Top",
        id: "HT90018",
        parentId: "11",
        Top: "11 mm ceramic Patagonia",
        Leg: "Iron cross in black brushed",
        Size: "L180xW90xH76",
    },
    {
        title: "HT92004",
        img: [Masa12_1, Masa12_2, Masa12_3, Masa12_3a],
        category: "Masa",
        subCategory: "Premium",
        subTableCategory: "Premium Extension",
        id: "HT92004",
        parentId: "12",
        Top: "10mm glass + 3mm ceramic (KL-177)",
        Base: "Black powder coated",
        Size: "L200/300xW100xH76",
    },
    {
        title: "HT90063",
        img: [Masa13_1, Masa13_2, Masa13_3, Masa13_4],
        category: "Masa",
        subCategory: "Premium",
        subTableCategory: "Premium Fix Top",
        id: "HT90063",
        parentId: "13",
        Top: "11 mm ceramic Patagonia",
        Leg: "Iron cross in black brushed",
        Size: "L180xW90xH76",
    },
    {
        title: "HT90047-158",
        img: [Masa14_1, Masa14_2, Masa14_3],
        category: "Masa",
        subCategory: "Premium",
        subTableCategory: "Premium Fix Top",
        id: "HT90047-158",
        parentId: "14",
        Top: "10mm glass + 6mm ceramic (GFYQGB24A14A)",
        Leg: "Dark bronze powder coated",
        Size: "Ø 158* H. 76",
    },
    {
        title: "HT90079",
        img: [Masa15_1, Masa15_2, Masa15_3],
        category: "Masa",
        subCategory: "Premium",
        subTableCategory: "Premium Fix Top",
        id: "HT90079",
        parentId: "15",
        Top: "10mm glass + 6mm ceramic (PANDORA)",
        Leg: "Stainless Steel in black polished",
        Size: "Ø 158xH. 76",
    },
    {
        title: "HT90063",
        img: [Masa16_1, Masa16_2, Masa16_3],
        category: "Masa",
        subCategory: "Premium",
        subTableCategory: "Premium Fix Top",
        id: "HT90063",
        parentId: "16",
        Top: "10mm glass + 6mm ceramic (GZ 10002)",
        Leg: "Stainless Steel in gold brushed / in black brushed",
        Size: "Ø.135xH76",
    },
    {
        title: "ZK1025DT",
        img: [Masa17_1, Masa17_2, Masa17_2a],
        category: "Masa",
        subCategory: "Premium",
        subTableCategory: "Premium Extension",
        id: "ZK1025DT",
        parentId: "17",
        Top: "10mm glass+3mm ceramic (KL-135)",
        Leg: "Powder coated in dark bronze color EXTENSION IN TWO SIDE",
        Size: "L160/240xW95xH76",
    },
    {
        title: "HT95006",
        img: [Masa18_1, Masa18_1a, Masa18_2, Masa18_3, Masa18_4],
        category: "Masa",
        subCategory: "Premium",
        subTableCategory: "Premium Extension",
        id: "HT95006",
        parentId: "18",
        Top: "11 mm ceramic Patagonia",
        Leg: "Iron cross in black brushed",
        Size: "L180xW90xH76",
    },
    {
        title: "HT90120",
        img: [Masa19_1, Masa19_2, Masa19_3, Masa19_4],
        category: "Masa",
        subCategory: "Premium",
        subTableCategory: "Premium Fix Top",
        id: "HT90120",
        parentId: "19",
        Top: "10mm glass+6mm ceramic (Pandora)",
        Leg: "Stainless steel in black brushed",
        Size: "L260xW115xH76",
    },
    {
        title: "HT90089",
        img: [Masa20_1, Masa20_2, Masa20_3],
        category: "Masa",
        subCategory: "Premium",
        subTableCategory: "Premium Fix Top",
        id: "HT90089",
        parentId: "20",
        Top: "11 mm ceramic Patagonia",
        Leg: "Iron cross in black brushed",
        Size: "L180xW90xH76",
    },
    {
        title: "HT90018",
        img: [Masa21_1, Masa21_2, Masa21_3],
        category: "Masa",
        subCategory: "Premium",
        subTableCategory: "Premium Fix Top",
        id: "HT90018",
        parentId: "21",
        Top: "11 mm ceramic Patagonia",
        Leg: "Iron cross in black brushed",
        Size: "L180xW90xH76",
    },
    {
        title: "HT90070",
        img: [Masa22_1, Masa22_2, Masa22_3, Masa22_4, Masa22_5],
        category: "Masa",
        subCategory: "Standard",
        subTableCategory: "Standard Fix Top",
        id: "HT90070",
        parentId: "22",
        Top: "10mm glass 3mm ceramic(KL 162)",
        Leg: "Stainless Steel in black brushed",
        Size: "L138xW138xH76 & L150xW150xH76",
    },
    {
        title: "ZK1003DT",
        img: [Masa23_1, Masa23_2, Masa23_3],
        category: "Masa",
        subCategory: "Premium",
        subTableCategory: "Premium Fix Top",
        id: "ZK1003DT",
        parentId: "23",
        Top: "11 mm ceramic Patagonia",
        Leg: "Iron cross in black brushed",
        Size: "L180xW90xH76",
    },
    {
        title: "HT90051",
        img: [Masa24_1, Masa24_2, Masa24_3, Masa24_4, Masa24_5, Masa24_6],
        category: "Masa",
        subCategory: "Premium",
        subTableCategory: "Premium Fix Top",
        id: "HT90051",
        parentId: "24",
        Top: "10mm glass 6mm ceramic",
        Leg: "Stainless steel in Black Brushed/in gold brushed",
        Size: "L252xW120xH76 & L278xW135xH76",
    },
    {
        title: "HT90069",
        img: [Masa25_1, Masa25_2, Masa25_3, Masa25_4],
        category: "Masa",
        subCategory: "Standard",
        subTableCategory: "Standard Fix Top",
        id: "HT90069",
        parentId: "25",
        Top: "10mm glass + 3mm ceramic (KL 80)",
        Leg: "Stainless steel in gold brushed/in black brushed",
        Size: "L140xW80xH76 & L180xW90xH76 & L220xW100xH76",
    },
    {
        title: "HT90074",
        img: [Masa26_1, Masa26_2, Masa26_3, Masa26_4],
        category: "Masa",
        subCategory: "Premium",
        subTableCategory: "Premium Fix Top",
        id: "HT90074",
        parentId: "26",
        Top: "10mm glass + 6mm ceramic (GFYQGB24A14A)",
        Base: "Stainless Steel in gold polished / in black polished",
    },
    {
        title: "HT90076",
        img: [Masa27_1, Masa27_2, Masa27_3],
        category: "Masa",
        subCategory: "Premium",
        subTableCategory: "Premium Fix Top",
        id: "HT90076",
        parentId: "27",
        Top: "10mm glass + 3mm ceramic (TL-188)",
        Leg: "Stainless steel in black polished",
        Size: "L200xW95xH76",
    }


]

export const productsDataChair = [

    {
        title: "S206",
        img: [Sandalyeler94, Sandalyeler6, Sandalyeler95, Sandalyeler96, Sandalyeler97, Sandalyeler98, Sandalyeler99, Sandalyeler100, Sandalyeler101],
        category: "Sandalye",
        id: "S206",
        parentId: "1",
        color: "Mealnge Grey + Golden S/S Frame",
        Size: "L60xW60xH75",
    },
    {
        title: "HD81232",
        img: [Sandalyeler7, Sandalyeler8, Sandalyeler9, Sandalyeler10],
        category: "Sandalye",
        id: "HD81232",
        parentId: "2",
        color: "BEIGE BREGO 31",
        Leg: "Anthracite powder coated",
        Size: "L62,5xW53xH91,5",

    },

    {
        title: "ZK6025",
        img: [Sandalyeler11, Sandalyeler12, Sandalyeler13, Sandalyeler14, Sandalyeler15],
        category: "Sandalye",
        id: "ZK6025",
        parentId: "3",
        color: "Light Beige BLW21-01#1",
        Size: "L59xW56xH90"
    },
    {
        title: "ZK6016",
        img: [Sandalyeler16, Sandalyeler17, Sandalyeler18, Sandalyeler19, Sandalyeler20],
        category: "Sandalye",
        id: "ZK6016",
        parentId: "4",
        color: "BEIGE 21-09 & BLACK DH402+DARK BRONZE N4#",
        Size: "L58xW53xH83",
    },
    {
        title: "HD81432",
        img: [Sandalyeler21, Sandalyeler22, Sandalyeler23, Sandalyeler24, Sandalyeler25, Sandalyeler26, Sandalyeler27],
        category: "Sandalye",
        id: "HD81432",
        parentId: "5",
        color: "OTE GREY MEG 20",
        Leg: " Grey rough finish coated",
        Size: "L47xW60,5xH90,5",
    },
    {
        title: "ZK6018",
        img: [Sandalyeler28, Sandalyeler29, Sandalyeler30, Sandalyeler31, Sandalyeler32, Sandalyeler33],
        category: "Sandalye",
        id: "ZK6018",
        parentId: "6",
        color: "GREY BLW21-01#5",
        Size: "L58xW60xH86",
    },
    {
        title: "ZK6044",
        img: [Sandalyeler34, Sandalyeler35, Sandalyeler36, Sandalyeler37, Sandalyeler38],
        category: "Sandalye",
        id: "ZK6044",
        parentId: "7",
        color: "White",
        Size: "L55xW56xH75",
    },
    {
        title: "ZK6013",
        img: [Sandalyeler39, Sandalyeler40, Sandalyeler41, Sandalyeler42, Sandalyeler43],
        category: "Sandalye",
        id: "ZK6013",
        parentId: "8",
        color: "GREY UP-633-37",
        Size: "L59,5xW64,5xH79",
    },
    {
        title: "ZK6022",
        img: [Sandalyeler44, Sandalyeler45, Sandalyeler46, Sandalyeler47],
        category: "Sandalye",
        id: "ZK6022",
        parentId: "9",
        color: "LIGHT BEIGE BLW21-01#1",
        Size: "L64xW58xH89",
    },

    {
        title: "ZK6024",
        img: [Sandalyeler48, Sandalyeler50, Sandalyeler51, Sandalyeler52, Sandalyeler53],
        category: "Sandalye",
        id: "ZK6024",
        parentId: "10",
        color: "LIGHT BEIGE BLW21-01#1+GUN BLACK FRAME",
        Size: "L59xW50xH90",
    },
    {
        title: "ZK6024",
        img: [Sandalyeler54, Sandalyeler55, Sandalyeler56, Sandalyeler57, Sandalyeler58, Sandalyeler59, Sandalyeler60, Sandalyeler61],
        category: "Sandalye",
        id: "ZK6024",
        parentId: "11",
        color: "LIGHT COFFEE BLW21-01#4+GUN BLACK FRAME",
        Size: "L59xW50xH90",
    },
    {
        title: "HD82004",
        img: [Sandalyeler62, Sandalyeler63, Sandalyeler64, Sandalyeler65],
        category: "Sandalye",
        id: "HD82004",
        parentId: "12",
        color: "BEIGE WT2013-102",
        Size: "L56xW48xH87",
    },
    {
        title: "ZK6020",
        img: [Sandalyeler66, Sandalyeler67, Sandalyeler68, Sandalyeler69, Sandalyeler70],
        category: "Sandalye",
        id: "ZK6020",
        parentId: "13",
        color: "LIGHT COFFEE BLW21-01#4",
        Size: "L55xW52xH81",
    },
    {
        title: "ZK6018",
        img: [Sandalyeler72, Sandalyeler72, Sandalyeler73, Sandalyeler74, Sandalyeler75, Sandalyeler76],
        category: "Sandalye",
        id: "ZK6018",
        parentId: "14",
        color: "LIGHT COFFEE BLW21-01#4",
        Size: "L58xW60xH86",
    },
    {
        title: "HD82009",
        img: [Sandalyeler77, Sandalyeler78, Sandalyeler79, Sandalyeler80, Sandalyeler81],
        category: "Sandalye",
        id: "HD82009",
        parentId: "15",
        color: "BEIGE UP633-10",
        Size: "L57xW44xH82",


    },
    {
        title: "HD81432-2",
        img: [Sandalyeler82, Sandalyeler83, Sandalyeler84],
        category: "Sandalye",
        id: "HD81432-2",
        parentId: "16",
        color: "OTE GREY MEG 20",
        Leg: "Grey rough finish coated",
        Size: "L46xW62xH92",
    },
    {
        title: "HD82009",
        img: [Sandalyeler85, Sandalyeler86, Sandalyeler87, Sandalyeler88, Sandalyeler89, Sandalyeler90],
        category: "Sandalye",
        id: "HD82009",
        parentId: "15",
        anotherColor: "Yes",
        color: "GREY UP-633-37",
        Size: "L57xW44xH82",

    },

    {
        title: "HD82003",
        img: [Sandalyeler91, Sandalyeler92, Sandalyeler93],
        category: "Sandalye",
        id: "HD82003",
        parentId: "18",
        color: "GREY 360-04",
        Size: "L55xW60xH79,5",
    },
    {
        title: "ZK6024",
        img: [Sandalyeler102, Sandalyeler103, Sandalyeler104, Sandalyeler105],
        category: "Sandalye",
        id: "ZK6024",
        parentId: "19",
        color: "LIGHT BEIGE BLW21-01#1+GUN BLACK FRAME",
        Size: "L59xW50xH90",
    },
    {
        title: "ZK6043",
        img: [Sandalyeler106, Sandalyeler107, Sandalyeler108, Sandalyeler109, Sandalyeler110],
        category: "Sandalye",
        id: "ZK6043",
        parentId: "20",
        color: "TAUPE WT2013-108",
        Size: "L57xW56xH76"
    },
    {
        title: "S201",
        img: [Sandalyeler111, Sandalyeler112, Sandalyeler113, Sandalyeler114],
        category: "Sandalye",
        id: "S201",
        parentId: "21",
        color: "Taupe",
        Size: "L45xW60xH83",
    },
    {
        title: "ZK6013",
        img: [Sandalyeler115, Sandalyeler116, Sandalyeler117, Sandalyeler118],
        category: "Sandalye",
        id: "ZK6013",
        parentId: "22",
        color: "BEIGE UP633-10",
        Size: "L59,5xW64,5xH79",
    },
    {
        title: "ZK6022",
        img: [Sandalyeler119, Sandalyeler120, Sandalyeler121, Sandalyeler122, Sandalyeler123],
        category: "Sandalye",
        id: "ZK6022",
        parentId: "23",
        color: "LIGHT COFFEE BLW21-01#4",
        Size: "L64xW58xH89",
    },
    {
        title: "ZK6025",
        img: [Sandalyeler124, Sandalyeler125, Sandalyeler126, Sandalyeler127, Sandalyeler128],
        category: "Sandalye",
        id: "ZK6025",
        parentId: "24",
        color: "LIGHT COFFEE BLW21-01#1",
        Size: "L59xW56xH90"
    },
    {
        title: "S253",
        img: [Sandalyeler129, Sandalyeler130, Sandalyeler131, Sandalyeler132],
        category: "Sandalye",
        id: "S253",
        parentId: "25",
        color: "COFFEE XC21-7+DARK BRONZE N4#",
        Size: "L61xW47xH81",
    },
    {
        title: "ZK6023",
        img: [Sandalyeler133, Sandalyeler134, Sandalyeler135, Sandalyeler136, Sandalyeler137, Sandalyeler138],
        category: "Sandalye",
        id: "ZK6023",
        parentId: "26",
        color: "LIGHT COFFEE BLW21-01#4",
        Size: "L64xW65xH89",
    },
    {
        title: "HD82004",
        img: [Sandalyeler139, Sandalyeler140, Sandalyeler141, Sandalyeler142, Sandalyeler143],
        category: "Sandalye",
        id: "HD82004",
        parentId: "27",
        color: "TAUPE WT2013-108",
        Size: "L56xW48xH87",
    }

]



